import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, CheckboxLabel } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import AccountsTableBase from "../Accounts/AccountsTableBase";
import IAccount from "../../models/IAccount";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { dynamicSort, handleBackdropClick, truncate } from "../../utility";
import { CheckedState } from "../../models/CheckedState";
import CheckBoxWithIndeterminateState from "../CheckBoxWithIndeterminateState";
import { queueExportUsersRequestAction } from "../../actions/productActions";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import { setSnackBarMessage } from "../../actions/generalActions";
import ActionMessageType from "../../models/ActionMessageType";
import MspType from "../../models/MspType";

interface IExportUserBillingListDialogProps {
  onSubmit: () => void;
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  dialogStep: number;
  title: string;
  selectedAccount: IAccount | undefined;
}

const ExportUserBillingListDialog: React.FC<IExportUserBillingListDialogProps> = ({ showDialog, dialogStep, onCancel, onSubmit, isActionInProgress, title, selectedAccount }) => {
  const items = useSelector((state: IAppState) => state.accountState.accountsNames);
  const loggedInUserEmail = useSelector((state: IAppState) => state.generalState.loggedUser?.email);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(dialogStep);
  const [nextButtonLabel, setNextButtonLabel] = useState("");
  const [displayedAccounts, setDisplayedAccounts] = useState(getDisplayedAccounts());
  const [allChecked, setAllChecked] = useState<CheckedState>(CheckedState.Unchecked);
  const [allCheckedByUser, setAllCheckedByUser] = useState(false);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedCount, setSelectedCount] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [includedAccountIds, setIncludedAccountIds] = useState<number[]>([]);
  const [excludedAccountIds, setExcludedAccountIds] = useState<number[]>([]);

  const [minHeight, setMinHeight] = useState(600);
  const [width, setWidth] = useState(480);
  const widthForExportStep = 400;
  const minHeightForExportStep = 100;

  function getDisplayedAccounts() {
    return items
      .filter(item => {
        if (item.type === MspType.Customer && item.closestParentId === selectedAccount?.id) {
          return true;
        }
        return false;
      })
      .map(item => ({ ...item }))
      .sort(dynamicSort("name"));
  }

  const onAllChecked = (checked: boolean) => {
    //reset both arrays - at this time all accounts are either selected or not
    setIncludedAccountIds([]);
    setExcludedAccountIds([]);

    setAllCheckedByUser(checked);

    const checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
    setAllChecked(checkedState);
    setSelectedCount(checked ? displayedAccounts.length : 0);
    const newDisplayedAccounts = displayedAccounts.map(account => {
      account.checkedState = checkedState;
      return account;
    });

    setDisplayedAccounts(newDisplayedAccounts);
  };

  const onItemSelected = () => {
    //we cannot select items - only check/uncheck them
  };

  function updateExcludedAccounts(itemId: number, checked: boolean) {
    if (checked) {
      //we added back an account that had been checked => remove it from the exclusions list
      const newExcludedAccountIds = [...excludedAccountIds];
      const itemIndex = newExcludedAccountIds.indexOf(itemId);
      newExcludedAccountIds.splice(itemIndex, 1);
      setExcludedAccountIds(newExcludedAccountIds);
    } else {
      //we removed an account when all had been checked => add it to exclusions list
      setExcludedAccountIds([...excludedAccountIds, itemId]);
    }
  }

  function updateIncludedAccounts(itemId: number, checked: boolean) {
    if (checked) {
      //we added an account => add to inclusions list
      setIncludedAccountIds([...includedAccountIds, itemId]);
    } else {
      //we removed an account => remove from inclusions list
      const newIncludedAccountIds = [...includedAccountIds];
      const itemIndex = newIncludedAccountIds.indexOf(itemId);
      newIncludedAccountIds.splice(itemIndex, 1);
      setIncludedAccountIds(newIncludedAccountIds);
    }
  }

  function syncInclusionAndExclusionsList(itemId: number, checked: boolean) {
    //if "Select All Accounts" is or was previously checked by user
    if (allCheckedByUser) {
      updateExcludedAccounts(itemId, checked);
      return;
    }

    //if "Select All Accounts" is not and was not checked by user
    updateIncludedAccounts(itemId, checked);
  }

  function updateSelectedCount(checked: boolean) {
    if (checked) {
      if (selectedCount + 1 === displayedAccounts.length) {
        setAllChecked(CheckedState.Checked);
      } else if (allChecked === CheckedState.Unchecked) {
        setAllChecked(CheckedState.Indeterminate);
      }

      setSelectedCount(previousCount => previousCount + 1);
      return;
    }

    if (selectedCount - 1 === 0) {
      setAllChecked(CheckedState.Unchecked);
    } else if (allChecked === CheckedState.Checked) {
      setAllChecked(CheckedState.Indeterminate);
    }
    
    setSelectedCount(previousCount => previousCount - 1);
  }

  function updateDisplayedAccounts(itemId: number, checked: boolean) {
    const newDisplayedAccounts = displayedAccounts.map(account => {
      if (account?.id === itemId) {
        account.checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
      }
      return account;
    });

    setDisplayedAccounts(newDisplayedAccounts);
  }

  const onItemChecked = (item: IAccount, checked: boolean): void => {
    syncInclusionAndExclusionsList(item.id, checked);
    updateSelectedCount(checked);
    updateDisplayedAccounts(item.id, checked);
  };

  const onPageSizeChanged = () => {
    //page size always remains the same
  };

  const onPageNumberChange = (value: number): void => {
    setSelectedPage(value);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div className="noPageSize" style={{ width: "525px", marginLeft: 5, height: "680px" }}>
            <div data-testid="exportUserBillingListInstructionsMessage" style={{ marginTop: -10, fontSize: 14 }}>
              Select the Accounts of which the desired User Billing Lists are to be exported.
            </div>
            {displayedAccounts.length > 0 && (
              <Box sx={{ border: 1, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", padding: 5 }}>
                <Grid container>
                  <Grid item xs={1}>
                    <CheckboxLabel data-testid={"ExportUserBillingListSelectAll"} control={<CheckBoxWithIndeterminateState size="small" disabled={false} onCheck={onAllChecked} checkedState={allChecked} />} />
                  </Grid>
                  <Grid item xs={11} style={{ display: "inline-flex", textAlign: "center", marginLeft: -10, marginTop: 10 }}>
                    <div style={{ fontWeight: "bold", marginRight: 5 }}>Select all Accounts</div>({selectedCount} Selected)
                  </Grid>
                </Grid>
              </Box>
            )}
            <div data-testid="exportUserBillingListAccountsTable">
              <AccountsTableBase isTextOnlyTable={true} showExpandIcon={false} pageSize={10} pageNumber={selectedPage} items={displayedAccounts} selectedAccount={undefined} hasSubpartners={false} viewSearchResults={false} loadingAccountId={undefined} onItemSelected={onItemSelected} onItemChecked={onItemChecked} onPageSizeChange={onPageSizeChanged} onPageNumberChange={onPageNumberChange} showCheckboxes={true} checkboxesDisabled={false} isBaLoggedIn={false} showInfoIcon={false} defaultPagination={[10]} />
            </div>
          </div>
        );
      case 1:
        return (
          <div style={{ width: width, minHeight: minHeight }} data-testid="exportUserBillingListConfirmationMessage">
            <p>
              Pressing export will send user billing information with <b>{truncate(selectedAccount?.name ?? "", 20)}</b> to <b>{loggedInUserEmail}</b>. It may take up to an hour for the email to be received.
            </p>
          </div>
        );
      default:
        break;
    }
  }

  function resetDialogData() {
    setIncludedAccountIds([]);
    setExcludedAccountIds([]);
    setAllChecked(CheckedState.Unchecked);
    setDisplayedAccounts(getDisplayedAccounts());
    setAllCheckedByUser(false);
  }

  useEffect(() => {
    setDisplayedAccounts(
      items
        .filter(item => {
          if (item.type === MspType.Customer && item.closestParentId === selectedAccount?.id) {
            return true;
          }
          return false;
        })
        .map(item => ({ ...item }))
        .sort(dynamicSort("name")),
    );
  }, [selectedAccount, items]);

  useEffect(() => {
    setNextButtonLabel(getNextButtonText(activeStep));
    setIsBackButtonVisible(activeStep !== 0);
  }, [activeStep]);

  useEffect(() => {
    if (selectedCount >= 1) {
      setNextEnabled(true);
      return;
    }

    setNextEnabled(false);
  }, [selectedCount]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    resetDialogData();
    onCancel();
  };

  const exportBillingUsers = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(queueExportUsersRequestAction(selectedAccount?.id, includedAccountIds, excludedAccountIds, allCheckedByUser));
      resolve(result);
    });

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        // resize the container to match the desired size for the next step
        setMinHeight(minHeightForExportStep);
        setWidth(widthForExportStep);
        break;
      case 1:
        exportBillingUsers().then(result => {
          if (result) {
            dispatch(
              setSnackBarMessage({
                message: ActionMessages[ActionTypes.ExportBillingUsers].infoMessage,
                type: ActionMessageType.Info,
              }),
            );
          }
        });
        resetDialogData();
        onSubmit();
        break;
    }
  };

  return (
    <Dialog className="exportBillingListDialog" disableEscapeKeyDown={isActionInProgress} data-testid="exportBillingListDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
      <div className="exportBillingList">
        <DialogTitle data-testid="exportBillingListDialogTitle" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <div className="DialogContentDiv">
            <Grid item xs={12} style={{ minHeight: minHeight }}>
              {getStepContent(activeStep)}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
          <Button data-testid="cancelExportBillingListBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleCancel}>
            CANCEL
          </Button>
          {isBackButtonVisible && (
            <Button data-testid="backExportBillingListBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleBack}>
              BACK
            </Button>
          )}
          <Button data-testid="confirmNextExportBillingListBtn" disabled={!nextEnabled} loadingPosition="start" size="large" type={"submit"} color="primary" isLoading={isActionInProgress} onClick={() => handleNext()}>
            {nextButtonLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ExportUserBillingListDialog;

export function getNextButtonText(activeStep: number): string {
  if (activeStep === 1) {
    return "EXPORT";
  }
  return "CONTINUE";
}
